<!--
File: Condition.vue
Description: show list of Condition data (IRI, rutting, etc.) entered in the Data100 table.
-->
<template>
  <md-card v-if="eligible">
    <md-card-header class="md-card-header-icon md-card-header-green">
      <div class="md-layout">
        <div class="md-layout-item md-small-size-100 md-size-20">
          <RegionsDropdown v-model="selectedRegion" @input='reloadData' />
        </div>
        <div class="md-layout-item md-small-size-100 md-size-20">
          <RoadsDropdown v-model="selectedRoad" :regionId="selectedRegion" :disabled='!selectedRegion'
            @input='reloadData' />
        </div>
        <div class="md-layout-item md-small-size-100 md-size-25">
          <SectionsDropdown v-model="selectedSection" :regionId="selectedRegion" :roadId="selectedRoad"
            :disabled='!selectedRegion' @input='reloadData' />
        </div>
        <div class="md-layout-item md-small-size-100 md-size-10">
          <YearsDropdown v-model="selectedYear" :items="yearsInConditionList" :disabled='!selectedSection' />
        </div>

        <div class="md-layout-item btn-row md-small-size-100">
          <md-button v-if="isBtnAllowed('AddButton')" class="md-success" @click="viewItem()">
            {{ $t('buttons.add') }}
          </md-button>
          <md-button v-if="total > 0" class="md-success" @click="excelExport">
            {{ $t('buttons.excel_export') }}
          </md-button>
        </div>
      </div>
    </md-card-header>

    <md-card-content>
      <md-progress-spinner v-if="showSpinner" :md-diameter="70" md-mode="indeterminate" />
      <md-table class="paginated-table table-striped table-hover" :value="queriedData" :md-sort.sync="currentSort"
        md-fixed-header :md-sort-order.sync="currentSortOrder" :md-sort-fn="customSort">
        <md-table-empty-state :md-label="$t('label.no_data')" :md-description="$t('messages.search_again')" />
        <md-table-row slot="md-table-row" slot-scope="{item}" :class="{ blinking: item.data_id === highlightedRow }"
          :id="item.data_id === highlightedRow ? 'highlightedRow' : null">
          <md-table-cell :md-label="$t('budget.year')" md-sort-by="survey_year">
            {{ item.survey_year }}
          </md-table-cell>
          <md-table-cell :md-label="$t('road_network.start_distance')" md-sort-by="start_distance" md-numeric>
            {{ item.start_distance }}
          </md-table-cell>
          <md-table-cell :md-label="$t('road_network.end_distance')" md-sort-by="end_distance" md-numeric>
            {{ item.end_distance }}
          </md-table-cell>
          <md-table-cell :md-label="$t('condition.iri')" md-sort-by="iri" md-numeric>
            {{ item.iri | numFormat }}
          </md-table-cell>
          <md-table-cell :md-label="$t('condition.rut_left')" md-sort-by="rut_left" md-numeric>
            {{ item.rut_left | numFormat }}
          </md-table-cell>
          <md-table-cell :md-label="$t('condition.rut_right')" md-sort-by="rut_right" md-numeric>
            {{ item.rut_right | numFormat }}
          </md-table-cell>
          <md-table-cell :md-label="$t('condition.cracking_m2')" md-sort-by="cracking_m2" md-numeric>
            {{ item.cracking_m2 | numFormat }}
          </md-table-cell>
          <md-table-cell :md-label="$t('condition.potholes_no')" md-sort-by="potholes_no" md-numeric>
            {{ item.potholes_no | numFormat }}
          </md-table-cell>
          <md-table-cell :md-label="$t('condition.edge_break_m2')" md-sort-by="edge_break_m2" md-numeric>
            {{ item.edge_break_m2 | numFormat }}
          </md-table-cell>
          <md-table-cell :md-label="$t('condition.repairs_m2')" md-sort-by="repairs_m2" md-numeric>
            {{ item.repairs_m2 | numFormat }}
          </md-table-cell>
          <md-table-cell :md-label="$t('tables.actions')">
            <div>
              <md-button class="md-just-icon md-default md-simple" :title="$t('buttons.edit')"
                @click.native="viewItem(item.data_id)">
                <md-icon>edit</md-icon>
              </md-button>
              <md-button v-if="isBtnAllowed('DeleteButton')" class="md-just-icon md-danger md-simple"
                :title="$t('buttons.delete')"
                @click.stop.prevent="deleteItem(item.data_id, `${item.section}: ${item.start_distance}-${item.end_distance}`)">
                <md-icon>delete</md-icon>
              </md-button>
            </div>
          </md-table-cell>
        </md-table-row>
      </md-table>
    </md-card-content>

    <md-card-actions md-alignment="space-between">
      <div class>
        <p class="card-category">
          {{ $t('label.showing_from_to_of_entries', { from: to > 0 ? from + 1 : 0, to: to, total }) }}
        </p>
      </div>
      <div class="md-layout-item md-small-size-100 md-size-15">
        <md-field>
          <label for="pages">{{ $t('label.per_page') }}</label>
          <md-select v-model="pagination.perPage" name="pages">
            <md-option v-for="item in pagination.perPageOptions" :key="item" :label="item" :value="item">
              {{ item }}
            </md-option>
          </md-select>
        </md-field>
      </div>
      <pagination class="pagination-no-border pagination-success" v-model="pagination.currentPage"
        :per-page="pagination.perPage" :total="total"></pagination>
    </md-card-actions>
  </md-card>
</template>
<script>
  import { mapState, mapGetters, mapActions } from 'vuex'
  import permissions from "@/mixins/permissionsMixin"
  import listView from '@/mixins/listViewMixin'
  import messages from '@/mixins/messagesMixin'
  import { RegionsDropdown, RoadsDropdown, SectionsDropdown, YearsDropdown } from '@/pages/Components'
  import { Pagination } from '@/components'
  import ExcelJS from 'exceljs'
  import { saveAs } from 'file-saver'

  export default {
    name: 'condition-list',
    mixins: [permissions, listView, messages],

    data() {
      return {
        formName: 'Condition',
        eligible: false,
        showSpinner: false,

        selectedRegion: null,
        selectedRoad: null,
        selectedSection: null,
        selectedYear: null,

        condition: {},
        searchQuery: null,  // Needed for compatibility with listViewMixin

        pagination: {
          perPage: 20,
          currentPage: 1,
          perPageOptions: [10, 20, 50, 100],
          total: 0
        },
        currentSort: 'start_distance',
        currentSortOrder: 'asc',
      }
    },
    components: {
      Pagination,
      YearsDropdown,
      RegionsDropdown,
      RoadsDropdown,
      SectionsDropdown
    },

    async mounted() {
      // Check if we are eligible to view the form
      this.eligible = await this.checkIfScreenAllowed()
      if (!this.eligible) {
        this.onClose()
        return
      };

      this.showSpinner = true
      this.clearList()
      const history = await this.loadHistory()
      if (history.form === 'condition' && history.use) {
        this.selectedRegion = history.data.selectedRegion
        this.selectedRoad = history.data.selectedRoad
        this.selectedSection = history.data.selectedSection
        this.selectedYear = history.data.selectedYear
        this.currentSort = history.data.currentSort
        this.currentSortOrder = history.data.currentSortOrder
        this.pagination.perPage = history.data.perPage
        //this.highlightedRow = history.row
        this.$nextTick(() => {
          this.pagination.currentPage = history.data.currentPage
        })
        this.clearHistory()
      }
      this.showSpinner = false
    },

    methods: {
      ...mapActions({
        loadList: 'LOAD_CONDITIONS_LIST',
        delete: 'DELETE_CONDITION',
        clearList: 'CLEAR_CONDITIONS_LIST'
      }),

      async reloadData() {
        this.showSpinner = true
        await this.loadList({section_id: this.selectedSection})
        this.showSpinner = false
      },

      save_history() {
        const hist = {
          form: 'condition',
          data: {
            selectedRegion: this.selectedRegion,
            selectedRoad: this.selectedRoad,
            selectedSection: this.selectedSection,
            selectedYear: this.selectedYear,
            currentSort: this.currentSort,
            currentSortOrder: this.currentSortOrder,
            perPage: this.pagination.perPage,
            currentPage: this.pagination.currentPage
          }
        }
        this.saveHistory(hist)
      },

      viewItem(id) {
        this.save_history()
        const path = !id ? '/condition_data/condition/add' : `/condition_data/condition/upd/${id}`
        this.$router.push(path)
      },

      fillWorkSheet(workbook, workbookName) {
        const headerCellFill = { type: 'pattern', pattern: 'solid', fgColor: { argb: 'FF0070C0' } } // Blue
        const worksheet = workbook.addWorksheet(workbookName);

        // Set the table headers
        worksheet.columns = this.getWorksheetColumns

        // Apply formatting to the table headers
        const headerRow = worksheet.getRow(1);
        headerRow.eachCell({ includeEmpty: true }, (cell) => { cell.fill = headerCellFill })
        headerRow.font = { bold: true, color: { argb: 'FFFFFFFF' } }; // White text
        headerRow.alignment = { vertical: 'middle', horizontal: 'center' };

        // Number formatting for the columns
        this.getWorksheetColumns.forEach(item => {
          if (item.num) worksheet.getColumn(item.key).numFmt = item.digits == 2 ? '#,##0.00' : '#,##0.000'
        })

        // Fill rows from tableData
        this.tableData.forEach(item => {
          worksheet.addRow(item);
        });
      },

      async excelExport() {
        // Create new Excel file
        const workbook = new ExcelJS.Workbook();
        this.fillWorkSheet(workbook, this.$t('route.condition_data'))

        // Save
        const fileName = `${this.tableData[0].section}.xlsx`
        const buffer = await workbook.xlsx.writeBuffer();
        const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        saveAs(blob, fileName);
      }
    },

    computed: {
      ...mapState({
        highlightedRow: (state) => state.History.row
      }),

      ...mapGetters(['conditionListFiltered', 'yearsInConditionList']),

      tableData() {
        return this.conditionListFiltered(this.selectedYear);
      },

      getWorksheetColumns() {
        return [
          { header: this.$t('budget.year'), key: 'survey_year', width: 5 },
          { header: this.$t('inventory.start_distance_m'), key: 'start_distance', width: 10, num: true, digits: 0 },
          { header: this.$t('inventory.end_distance_m'), key: 'end_distance', width: 10, num: true, digits: 0 },
          { header: this.$t('condition.iri'), key: 'iri', width: 10, num: true, digits: 2 },
          { header: this.$t('condition.rut_left'), key: 'rut_left', width: 10, num: true, digits: 2 },
          { header: this.$t('condition.rut_right'), key: 'rut_right', width: 10, num: true, digits: 2 },
          { header: this.$t('condition.cracking_m2'), key: 'cracking_m2', width: 10, num: true, digits: 2 },
          { header: this.$t('condition.potholes_no'), key: 'potholes_no', width: 10, num: true, digits: 2 },
          { header: this.$t('condition.edge_break_m2'), key: 'edge_break_m2', width: 10, num: true, digits: 2 },
          { header: this.$t('condition.repairs_m2'), key: 'repairs_m2', width: 10, num: true, digits: 2 },
        ]
      },
    },

    watch: {
      async highlightedRow(newVal) {
        if (newVal) {
          await this.reloadData();
          // calculate - which page edited item belongs to
          const ind = this.searchedData.findIndex(item => item.data_id == newVal)
          this.pagination.currentPage = Math.ceil(ind / this.pagination.perPage) || 1
          this.scrollToHighlightedRow();
          this.clearHistory();
        }
      },
    }
  }
</script>
<style lang="scss" scoped>
.cell-actions {
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  .md-button {
    margin: 3px 0;
    min-width: 100px;
  }
}

.md-table-head-label {
  padding-right: 0;
}

.btn-row {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
}

.md-card {
  margin: 0px 0;
}

.md-card .md-card-actions {
  border: 0;
  margin-left: 20px;
  margin-right: 20px;
}

.md-button+.md-button {
  margin-left: 10px;
}

.md-progress-spinner {
  margin: 18px;
  position: absolute;
  top: 25%;
  left: 45%;
  z-index: 20;
}

.blinking {
  animation: blink-animation 1s linear infinite;
}

@keyframes blink-animation {
  50% {
    background-color: pink;
  }
}
</style>